<template>
  <base-layout :loadingFlag="$store.state.loading">
    <h1 class="title">Page Not Found</h1>
    <h2 class="title">Contact Us</h2>
    <p class="content">
      {{ globalConfig.generalContactMessage }}
    </p>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/EmptyBaseLayout.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { BaseLayout },
  computed: {
    ...mapState(["globalConfig"])
  },
  methods: {
    ...mapActions(["fetchPaymentPortalGlobalConfig"])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchPaymentPortalGlobalConfig();
    });
  }
};
</script>
